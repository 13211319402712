import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import MyRouter from "./router/MyRouter";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <MyRouter />
  </Router>
);
